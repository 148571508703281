.languageSelector{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;

    display: flex;
    gap: 10px;
}


.btn-language{
    color: rgb(185, 0, 0);
    background-color: transparent;
    border: none;

    font-size: 1.1rem;
    font-weight: bold;

    cursor: pointer;
    border-bottom: solid transparent;
}

.btn-language-selected-white{
    color: white;
    background-color: transparent;
    border: none;

    font-size: 1.3rem;
    font-weight: bold;
    
    cursor: pointer;

    border-bottom: 2px solid white;
    border-radius: 1px;
}

.btn-language-selected-black{
    color: black;
    background-color: transparent;
    border: none;

    font-size: 1.3rem;
    font-weight: bold;
    
    cursor: pointer;

    border-bottom: 2px solid black;
    border-radius: 1px;
}

.languageSelector-menu{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 20px;

    display: flex;
    gap: 10px;
}