.btn{
    width: clamp(100px, 25vw, 200px);
    height: 50px;

    border: none;
    border-radius: 10px;

    color: white;
    font-size: 1.2rem;
    font-weight: bold;

    background-color: rgb(185, 0, 0);

    cursor: pointer;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.animateTop {
    animation: slideInFromTop 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}