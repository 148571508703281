.title{
    font-size: 3.5rem;
    font-weight: bold;

    margin-top: clamp(25px, 3vh, 50px);
    margin-bottom: 10px;
}

.nav{
    width: 100%;

    padding: 10px 0 25px 0;
}

.nav ul{
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 40px;

    list-style: none;

    font-weight: bold;
    font-size: 1.3rem;
}

.nav ul li:hover{
    cursor: pointer;
}

.liItem{
    border-bottom: 2px solid transparent;
}

.selectedItem{
    border-bottom: 2px solid black;
    transform-origin: center;
    transition: border-bottom 0.5s ease-in;
}

.sliderContainer{
    margin: 0 10px 40px 10px;
}


.service-box-with-widgets5 {
    background-image: url("../../images/services/renting1.jpeg");

    height: 100%;
    width: 65%;

    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    position: relative;
    border-radius: 25px;
}

.service-box-with-widgets6 {
    background-image: url("../../images/services/insurance.jpeg");

    height: 100%;
    width: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    position: relative;
    border-radius: 25px;
}

.service-box-with-widgets9 {
    background-image: url("../../images/services/guided_travel1.jpg");

    height: 100%;
    width: 65%;

    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    position: relative;
    border-radius: 25px;
}

.widgets-box{
    height: 100%;
    width: 35%;

    /*display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    
    align-items: center;
    justify-items: center;*/
    margin-left: 20px;
    
    display: flex;
    flex-wrap: wrap; /* Garante que os itens sejam embrulhados em uma nova linha quando não couberem horizontalmente */
    justify-content: space-between; /* Distribui os widgets uniformemente na horizontal */
    align-content: space-between; /* Distribui os widgets uniformemente na vertical */
}

.widgets-box-vertical{
    height: 100%;
    width: 35%;

    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.service-box-with-widgets-wrapper{
    width: 100%;
    height: 65vh;

    display: flex;
    flex-direction: row;
    align-items: center;
}

/*.service-box-with-widgets {
    height: 100%;
    width: 65%;

    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    position: relative;
    border-radius: 25px;
}*/

/* Classe para deslizar da direita para a esquerda */
.slideLeft {
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
}

/* Classe para deslizar da esquerda para a direita */
.slideRight {
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
}

/* Classe para deslizar da esquerda para o centro */
.slideLeftToCenter {
    animation: slideLeftToCenter 0.2s ease-in-out;
}

/* Classe para deslizar da direita para o centro */
.slideRightToCenter {
    animation: slideRightToCenter 0.2s ease-in-out;
}

@keyframes slideLeftToCenter {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideRightToCenter {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}


@media screen and (max-width: 1450px) {
    .service-box-with-widgets-wrapper{
        height: 70vh;
    }
}