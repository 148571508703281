.tours-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    min-height: 50vh;

    padding-top: 5em;
    padding-right: 5em;
    padding-bottom: 0px;
    padding-left: 5em;
}

.tours-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.categories-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.tours-container__banners-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    flex-wrap: wrap;

    width: 100%;
}

.tours-container__banners-container--empty__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 0em;
}

.tours-container__banners-container--empty {
    font-size: xx-large;
    font-weight: bold;
    color: rgb(185, 0, 0);
}

.tours-container__banners-container--empty-info {
    font-size: medium;
}