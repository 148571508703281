.services-scroll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin: 0 100px;
    width: calc(100vw - 200px); /* Subtrai 200px para considerar as margens */
    gap: 20px;
}

.service-box {
    width: 100%; /* Estica o elemento para ocupar a largura do container */
    height: 85vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    position: relative;
    border-radius: 25px;
}

/*.vertical {
    height: 100%;
    width: 35%;

    background-color: red;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}*/

.service-box:nth-child(1) {
    background-image: url("../../images/services/aquisicao.jpeg");
}

.service-box:nth-child(2) {
    background-image: url("../../images/services/peakpx.jpg");
}

.service-box:nth-child(3) {
    background-image: url("../../images/services/estacionamento.jpeg");
}

.service-box:nth-child(4) {
    background-image: url("../../images/services/maintenance.jpeg");
}

/*.service-box:nth-child(5) {
    background-image: url("../../images/services/renting1.jpeg");
}

.service-box:nth-child(6) {
    background-image: url("../../images/services/insurance.jpeg");
}*/

.service-box:nth-child(5) {
    background-image: url("../../images/services/apoio_juridico.jpeg");
}

.service-box:nth-child(6) {
    background-image: url("../../images/services/accounting_support.jpg");
}

/*.service-box:nth-child(9) {
    background-image: url("../../images/services/guided_travel1.jpg");
}*/

.service-box:nth-child(7) {
    background-image: url("../../images/services/real_estate.jpg");
}

.service-box:nth-child(8) {
    background-image: url("../../images/services/personalized_service.jpeg");
}

.service-box p{
    color: white;
    font-weight: bold;
    font-size: 1.2rem;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra do texto */
}

.service-box-title{
    padding: 30px 0;
    height: 15%;

    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra do texto */
}

.service-box-titleW{
    padding: 30px 0;
    height: 15%;

    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra do texto */
}

.service-box-footerW{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    padding: clamp(25px, 5vw, 50px) 0;
}

.service-box-footerW p{
    color: white;
    font-weight: bold;
    font-size: large;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra do texto */
}

.text-shadow{
    color: white;
    font-weight: bold;
    font-size: large;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra do texto */
}

.service-box-footer{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    padding: clamp(25px, 5vw, 50px) 0;
}

.service-box-footer-btns{
    flex: 1;
    display: inline-flex;
    align-items: flex-end;
    gap: 40px;
}

.service-box-footer-btns button{
    border: none;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra do texto */
}

.service-box-footer-btns > *:first-child{
    background-color: transparent;
    padding: 15px 45px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 2px solid white;
    border-radius: 2px;

    position: relative;
}

.service-box-footer-btns > *:first-child:hover{
    border: none;
    cursor: pointer;
}

.service-box-footer-btns > *:first-child:hover::before{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform-origin: center;
    transform: scaleX(0);
    animation: border-animation 1.5s ease-in forwards;

}

.animate-expand4 {
    /* Adicione qualquer estilo de animação desejado aqui */
    transition: height 0.3s, transform 0.3s ease-in-out;
    height: 150px; /* Defina a altura inicial */
    width: 100%;
    transform: skew(0, -5deg); /* Sem rotação inicial */

    background-image: url("../../images/services/apoio_juridico.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    border-radius: 25px;
    margin: 50px 0 0 0;
}

.animate-expand5 {
    /* Adicione qualquer estilo de animação desejado aqui */
    transition: height 0.3s, transform 0.3s ease-in-out;
    height: 150px; /* Defina a altura inicial */
    width: 100%;
    transform: skew(0, -5deg); /* Sem rotação inicial */

    background-image: url("../../images/services/accounting_support.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    border-radius: 25px;
    margin: -25px 0 0 0;
}

.animate-expand6 {
    /* Adicione qualquer estilo de animação desejado aqui */
    transition: height 0.3s, transform 0.3s ease-in-out;
    height: 150px; /* Defina a altura inicial */
    width: 100%;
    transform: skew(0, -5deg); /* Sem rotação inicial */

    background-image: url("../../images/services/real_estate.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    border-radius: 25px;
    margin: -25px 0 0 0;
}

.animate-expand7 {
    transition: height 0.3s, transform 0.3s ease-in-out;
    height: 150px; /* Defina a altura inicial */
    width: 100%;
    transform: skew(0, -5deg); /* Sem rotação inicial */

    background-image: url("../../images/services/personalized_service.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    border-radius: 25px;
    margin: -25px 0 0 0;
}
  
.animate-expand4:hover {
    height: 70vh; /* Altura aumenta no hover */
    transform: skew(0, 0); /* Rotação (skew) no hover */
}
.animate-expand5:hover {
    height: 70vh; /* Altura aumenta no hover */
    transform: skew(0, 0); /* Rotação (skew) no hover */
}
.animate-expand6:hover {
    height: 70vh; /* Altura aumenta no hover */
    transform: skew(0, 0); /* Rotação (skew) no hover */
}
.animate-expand7:hover {
    height: 70vh; /* Altura aumenta no hover */
    transform: skew(0, 0); /* Rotação (skew) no hover */
}

@keyframes border-animation{
    to{
        transform: scaleX(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animateFade{
    animation: fadeIn 2.5s ease-in;
}

@media screen and (max-width: 1280px) {
    .service-box-with-widgets-wrapper {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .services-scroll-container {
        margin: 0px 20px; /* Reduza as margens para dispositivos móveis */
        width: calc(100vw - 40px); /* Reduza a largura para dispositivos móveis */
    }

    .service-box {
        height: 50vh; /* Remova a altura fixa para se adaptar ao conteúdo */
    }
}

@media screen and (max-width: 900px) {

    .service-box p{
        font-size: 1.1rem;
    }
    
    .service-box:nth-child(1) {
        background-image: url("../../images/services/aquisicao.jpeg");
    }
    
    .service-box:nth-child(2) {
        background-image: url("../../images/services/peakpx.jpg");
    }
    
    .service-box:nth-child(3) {
        background-image: url("../../images/services/estacionamento.jpeg");
    }
    
    .service-box:nth-child(4) {
        background-image: url("../../images/services/maintenance.jpeg");
    }
    
    .service-box:nth-child(5) {
        background-image: url("../../images/services/renting1.jpeg");
    }
    
    .service-box:nth-child(6) {
        background-image: url("../../images/services/insurance.jpeg");
    }
    
    .service-box:nth-child(7) {
        background-image: url("../../images/services/apoio_juridico.jpeg");
    }
    
    .service-box:nth-child(8) {
        background-image: url("../../images/services/accounting_support.jpg");
    }
    
    .service-box:nth-child(9) {
        background-image: url("../../images/services/guided_travel1.jpg");
    }
    
    .service-box:nth-child(10) {
        background-image: url("../../images/services/real_estate.jpg");
    }
    
    .service-box:nth-child(11) {
        background-image: url("../../images/services/personalized_service.jpeg");
    }

}