.timeline-container {
    position: relative; 
    min-height: 200vh;
    height: 100%; 
    width: 1em;
    background-color: #ddd;
    border-radius: 1em;
}

.timeline {
    position: absolute;
    width: 100%;
    height: 0;
    background-color: rgb(185, 0, 0);
    transition: height 0.4s ease-out; /* Increased the duration slightly for smoother effect */
    border-radius: 1em;
}

.timeline-point {
    position: absolute;
    left: -5px;
    width: 1.25em;
    height: 1.25em;
    background-color: white;
    border: 3px solid rgb(185, 0, 0);
    border-radius: 50%;
}

.timeline-point span {
    position: absolute;
    top: 0;
    left: 30px;
    font-size: x-large;
    font-weight: bold;
    color: rgb(185, 0, 0);
    width: 10em;
}

.timeline-point span .story-title {
    color: rgb(0, 0, 0);
}

.story-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

/* --------------------------------- */
.timeline-containerV2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5em;

    padding: 1em 5em 1em 5em;
}

.story-divV2 {
    display: flex;
    flex-direction: column;
    gap: 2.5em;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.story-titleV2 {
    font-size: 2.25rem;
    font-weight: bold;
    color: rgb(185, 0, 0);

    width: 100%;
    display: flex;
    align-items: flex-start;

}