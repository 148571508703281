/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Cor de fundo cinza com transparência */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Garante que o modal esteja sempre na parte superior */
}

.modal {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px 20px 0 20px;
    height: clamp(600px, 60vh, 700px);
    max-width: 80%;
    transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
}

.modal-entering,
.modal-entered {
    transform: translateY(0);
    opacity: 1;
}

.modal-exiting,
.modal-exited {
    transform: translateY(100%);
    opacity: 0;
}

.modal-close{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: 40px;
    width: 100%;
}

.modal-close-btn{
    border: none;
    background-color: white;

    font-weight: bolder;
    font-size: 1.5rem;
}

.modal-close-btn:hover{
    color: rgb(185, 0, 0);

    cursor: pointer;
}

.modal-close-btn:hover{
    border: none;
}

.content{
    display: flex;
    flex-direction: row;

    height: clamp(500px, 50vh, 600px);
}

.content .left-div{
    background-color: red;
    width: 65%;
    
    border-radius: 25%;
}

.content .right-div{
    width: 35%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: clamp(25px, 10vh, 35px);

    gap: 35px;
}

.left-div img{
    height: 100%;
    width: 100%;

    border-radius: 2%;
}