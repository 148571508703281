.flex-container-footer{
    padding: 1em 2em;
    margin-top: clamp(50px, 5vh, 100px);

    min-height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 -4px 4px -2px rgba(0, 0, 0, 0.2);
}

.flex-container-footer-noMargin{
    padding: 1em 2em;

    min-height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 -4px 4px -2px rgba(0, 0, 0, 0.2);
}

.footer img{
    max-width: 25%;
    max-height: 20%;
}

.wrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 100px;
    margin-right: 40px;
}

.icons-container{
    display: flex;
}

.icons-container a{
    text-decoration: none;
    color: black;
}

.icon{
    margin: 10px;
}

.icon:hover{
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .flex-container-footer {
        display: none;
    }

    .flex-container-footer-noMargin{
        display: none;
    }
}