.flex-container{
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    padding: 1em 2em;

    height: 15vh;
}

.btns-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.header img{
    max-width: 25%;
    margin-left: 20px;
}

.logo-220{
    transform: translateX(-2.3rem);
    height: 220px;
}

.logo-120{
    height: 120px;
}

.logo:hover{
    cursor: pointer;
}

.nav-button{
    margin: 5px;
    padding: 10px 30px;

    background-color: transparent;
    font-size: 1.25rem;
    font-weight: bold;
    border: 4px solid transparent;

    color: rgb(185, 0, 0);
}

.nav-button:active{
    border: 4px solid transparent;
}

.nav-button-selected{
    border-radius: 3px;
    color: rgb(185, 0, 0);
    border-bottom: rgb(185, 0, 0) solid;
}

.nav-button-selected-home{
    border-radius: 3px;
    color: white;
    border-bottom: white solid;
}

.nav-button:hover{
    cursor: pointer;

    border-radius: 3px;
    color: rgb(185, 0, 0);
    border-bottom: rgb(185, 0, 0) solid; /*tendo so esta linha sem ter o border quando nao esta hover, o border ao ser adicionado ia afetar os outros botos na sua posicao*/
    transition: border 0.25s ease;
}

.nav-button-home:hover{
    cursor: pointer;

    border-radius: 3px;
    color: rgb(185, 0, 0);
    border-bottom: rgb(185, 0, 0) solid; /*tendo so esta linha sem ter o border quando nao esta hover, o border ao ser adicionado ia afetar os outros botos na sua posicao*/
    transition: border 0.25s ease;
}

.mobile-nav-toogle{
    display: none;
}

.flex-container-home {
    display: flex;
    flex-direction: column; /* Change to column layout */
    align-items: center;
    justify-content: flex-start;
    /*box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);*/
    padding: 1em 2em;
    height: 100vh;
    
    /*margin-bottom: clamp(25px, 5vh, 50px);*/

    position: relative; /* Para posicionar elementos filhos */
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/nav-background3.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    /*filter: blur(3px); /* Aplicar o filtro de desfoque */
    z-index: -1; /* Colocar o plano de fundo atrás do conteúdo */
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    height: 15vh;
}

.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px; /* Adjust margin as needed */
    padding: 20px; /* Add padding for better spacing */

    flex: 1;
    width: 100%;

    color: white;
}

.text-div{
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    font-size: 1.5rem;
}

.btn-container-contactUs{
    flex: 1;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    width: 100%;
    margin-top: 50px;
}

@media (max-width: 950px){
    .btns-container-toggled{
        position: fixed;
        inset: 0 0 0 50%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        gap: 2em;

        padding: min(30vh, 10rem) 2em;

        /*background-color: hsl(0 0% 100% / 0.95);

        background-blur: (1rem);*/
        background-color: white;
        z-index: 1000;

        box-shadow: -4px 0 4px -2px rgba(0, 0, 0, 0.4);
    }

    .mobile-nav-toogle{
        display: block;
        position: absolute;
        z-index: 99999;
        width: 3rem;
        aspect-ratio: 1;
        top: 5rem;
        right: 2.5rem;

        /* Alterar o fundo para o ícone SVG */
        background-image: url("../../images/burger-menu-svgrepo-com.svg");
        background-size: contain; /* Ajustar o tamanho do ícone SVG */
        background-repeat: no-repeat;
        background-color: transparent; /* Remover fundo branco */
        border: none;
    }

    .mobile-nav-toogle:hover{
        cursor: pointer;
    }

    .hide {
        opacity: 0;
        transform: translateY(-100%);
        pointer-events: none;
    }

    .logo-220{
        height: 100px;
    }

    .logo-120{
        height: 80px;
    }
}

@media (max-width: 950px) and (orientation: landscape) {
    .flex-container-home {
        height: auto; /* Altere a altura para se ajustar ao conteúdo */
    }
}

/* not used */
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.animateRight {
    animation: slideInFromRight 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.animateTop {
    animation: slideInFromTop 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}