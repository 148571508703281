.map-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 81vh;
}

#title-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    position: relative; /* Adicione position relative para posicionar o ::before */
}

#title-contact::before {
    content: "";
    position: absolute;
    bottom: 0; /* Posicione no fundo */
    left: 50%; /* Centralize horizontalmente */
    transform: translateX(-50%); /* Centralize totalmente */
    width: 80%; /* Largura do border */
    border-bottom: 3px solid rgb(185, 0, 0); /* Defina a largura e a cor do border */
    border-radius: 3px;
}

.contact-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    background-image: url("../../images/nav-background3.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    z-index: -1;

    padding: 50px 0;
}

.form-container{
    width: 60%;

    margin: 30px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    text-align: left;

    background-color: transparent;
}

.form-container input[type=text] {
    display: inline-block;
    height: 30px;
    width: calc(100% - 30px);
    font-size: 15px;
    padding: 10px 10px 10px 15px;
    margin-top: 3px;
    margin-bottom: 3px;
    border: 2px solid transparent;

    background-color: rgba(255, 255, 255, 0.8);
}

.form-container input[placeholder=Subject] {
    position: relative;
}

.close{
    position: absolute;
    right: 0;
    transform: translateX(-20px);
    font-weight: bold;
    font-size: x-large;
}

.close:hover{
    cursor: pointer;
}

.form-container textarea{
    display: inline-block;
    height: 20vh;
    width: calc(100% - 30px);
    font-size: 15px;
    padding: 10px 10px 10px 15px;
    margin-top: 3px;
    margin-bottom: 3px;
    
    border: 2px solid transparent;

    overflow: hidden;
    resize: none;

    background-color: rgba(255, 255, 255, 0.8);
}

input[type=submit]{
    width: clamp(100px, 25vw, 200px);
    height: 50px;

    border: none;
    border-radius: 10px;

    color: white;
    font-size: large;
    font-weight: bold;

    background-color: rgb(185, 0, 0);
    margin-top: 3px;

    cursor: pointer;

    color: white;
    font-size: medium;
    cursor: pointer;
}

input[type=submit]:hover{
    transform: scale(1.1);
    transition: 0.5s ease;
    font-weight: bold;
}

.contacts-info-container{
    width: 40%;

    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 35px 0;
}

.box{
    width: 100%;
    height: 12vh;
   
    border: none;
    box-shadow: 0 0 2px 2px rgb(185, 0, 0);
    -moz-box-shadow: 0 0 2px 2px rgb(185, 0, 0);
    -webkit-box-shadow: 0 0 2px 2px rgb(185, 0, 0);
    margin-bottom: 20px;

    color: white;
    background-color: rgba(185, 0, 0, 0.7);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

*:focus{
    outline: none !important;
    border: 2px solid rgb(185, 0, 0);
}
*::placeholder{
    color: black;
}

.form-container textarea:focus{
    outline: none !important;
    border: 2px solid rgb(185, 0, 0);
}

.form-container input[type=text]:focus{
    outline: none !important;
    border: 2px solid rgb(185, 0, 0);
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.animateTop {
    animation: slideInFromBottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animateFade{
    animation: fadeIn 3s ease;
}

@media screen and (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .form-container{
        width: 75%;
    }

    .contacts-info-container{
        width: 75%;
    }
  }