.full-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.text {
    position: absolute;
    left: 50%;
    bottom: 0;
    color: white;
    transform: translateX(-50%);
    margin-bottom: 50px;

    font-size: 1.1em;
    font-weight: bolder;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 25px;

    padding: 5px;
}

/*.depositions-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    width: 100%;

    background-color: rgb(185, 0, 0);
    padding: 50px 10px;

    margin-top: clamp(20px, 5vw, 10px);
    margin-bottom: clamp(20px, 5vw, 10px);

    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;

    -moz-box-shadow: 0 0 10px #999;
    -webkit-box-shadow: 0 0 10px #999;
    box-shadow: 0 0 10px #999;
    border-radius: 25px;
}

.deposition-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#title{
    display: flex;
    align-items: center;
    padding-top: clamp(5px, 5vw, 15px);
    padding-left: clamp(5px, 5vw, 15px);

    font-size: 4em;
}

.deposition{
    margin: 20px;

    height: clamp(150px, 25vw, 250px);
    width: clamp(150px, 25vw, 250px);
    border-radius: 25px;
}*/

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.deposition.animateLeft {
    animation: slideInFromLeft 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.deposition.animateTop {
    animation: slideInFromTop 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.deposition.animateBottom {
    animation: slideInFromBottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.deposition.animateRight {
    animation: slideInFromRight 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@media screen and (max-width: 768px) {
    .full-container {
        margin-top: 35px;
        margin-bottom: 0;
    }

    .services-slide{
        display: none;
    }
}
