.section-container{
    width: 100%;

    /*margin-top: clamp(25px, 5vw, 50px);*/
    /*margin-bottom: clamp(50px, 5vw, 100px);*/

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.services-nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 90%;
}

.cards-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cards-wrapper .arrows button{
    border: none;
    background-color: white;
    color: rgb(185, 0, 0);
    font-size: 1.2em;

    margin: 0 20px;
    cursor: pointer;
}

#title-services {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em;
    position: relative; /* Adicione position relative para posicionar o ::before */
}

#title-services::before {
    content: "";
    position: absolute;
    bottom: 0; /* Posicione no fundo */
    left: 50%; /* Centralize horizontalmente */
    transform: translateX(-50%); /* Centralize totalmente */
    width: 80%; /* Largura do border */
    border-bottom: 3px solid rgb(185, 0, 0); /* Defina a largura e a cor do border */
    border-radius: 3px;
}

.cards-container{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr)); /* 4 colunas fixas */
    gap: clamp(20px, 10vw, 30px);
    max-width: 90%; /* Aumente a largura máxima para preencher o contêiner pai */
    
    justify-content: center; /* Centralizar horizontalmente */
    align-items: center; /* Centralizar verticalmente */
}

.cards-container-twoElements {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 2 colunas */
    gap: clamp(20px, 10vw, 30px);

    max-width: 100%; /* Aumente a largura máxima para preencher o contêiner pai */

    justify-content: center; /* Centralizar horizontalmente */
    align-items: center; /* Centralizar verticalmente */

}

.service-card{
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    height: clamp(200px, 50vh, 300px);
    max-width: 400px;
    /*width: clamp(200px, 25vw, 300px);*/

    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999;
    border-radius: 25px;
}

.service-card:hover{
    cursor: pointer;
}

.service-icon{
    height: 50%;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}

.service-title{
    height: 15%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    box-shadow: 0 1px 4px 0 rgba(0,0,0,.2);
}

.service-description{
    flex: 1;

    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.9em;
    color: grey;
}

.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;

    margin-top: 25px;
}

.pagination button {
    border: none;
    background: white;

    font-size: 1.5rem;
    font-weight: bold;

    margin: 15px;
    width: 25px;
}

.pagination button:hover{
    cursor: pointer;
}

.selected{
    color: rgb(185, 0, 0);
    font-size: 2rem !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animateFade{
    animation: fadeIn 3s ease;
}

@media (max-width: 599px) {
    .cards-container,
    .cards-container-twoElements {
        grid-template-columns: 1fr; 
        gap: 10px; 
        max-width: 90%; 
    }
}

@media (min-width: 600px) and (max-width: 959px) {
    .cards-container,
    .cards-container-twoElements {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
        gap: 20px; 
        max-width: 90%; 
    }

    .cards-wrapper .arrows button{
        display: none;
    }
}

.fade-in {
    opacity: 1; /* Inicia completamente transparente */
    transition: opacity 0.25s ease-in-out; /* Duração e tipo de transição */
}

/* Estilo para fade-out */
.fade-out {
    opacity: 0; /* Inicia completamente visível */
    transition: opacity 0.25s ease-in-out; /* Duração e tipo de transição */
}

@media screen and (max-width: 768px) {
    .pagination button {
        color: black;
    }

    .selected{
        color: rgb(185, 0, 0) !important;
        font-size: 2rem !important;
    }
}