.stories-page {
    overflow-x: hidden;
}

.stories-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15em 5em 15em 8em;
    
    min-height: 200vh;
    width: 100%;

    position: relative;
    overflow-y: hidden;
}

.stories-container__story {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.stories-container__timeline-container {
    min-height: 200vh;
    height: 100%;
    width: 12.5em;
}