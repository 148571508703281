.App {
  text-align: center;
}

/*html{
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}*/

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
