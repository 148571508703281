.widget {
    position: relative;
    background-color: rgba(218, 218, 218, 0.3);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: calc(50% - 10px);
    height: calc(50% - 10px);
    margin: 0 5px;
    border-radius: 25px;
    overflow: hidden;
    border: none;
}

.widget::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1);
    transition: transform 0.3s ease; /* Adicione uma transição suave */
}

.widget-5-0::before{
    background-image: url("../../../images/services/microServices/rental_car.png");
}

.widget-5-1::before{
    background-image: url("../../../images/services/microServices/rental_motorbike.png");
}

.widget-5-2::before{
    background-image: url("../../../images/services/microServices/rental_motorhomes.png");
}

.widget-5-3::before{
    background-image: url("../../../images/services/microServices/rental_bicycle.png");
}


.widget-9-0::before{
    background-image: url("../../../images/services/microServices/guided_car.png");
}

.widget-9-1::before{
    background-image: url("../../../images/services/microServices/guided_motorcycle.png");
}

.widget-9-2::before{
    background-image: url("../../../images/services/microServices/guided_bike.png");
}

.widget-9-3::before{
    background-image: url("../../../images/services/microServices/guided_hiking.png");
    transform: scale(.8) translateY(-15%);
}


.widget:hover::before {
    transform: scale(1.08); /* Aplicar um zoom na imagem de fundo em hover */
}

.widget-9-3:hover::before {
    transform: scale(0.86) translateY(-10%); /* Aplicar um zoom na imagem de fundo em hover */
}

.widget-full-width{
    position: relative;
    background-color: rgba(218, 218, 218, 0.3);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: calc(90% - 10px);
    height: calc(95% - 10px);
    margin: 5px 0;
    border-radius: 25px;
    overflow: hidden;
    border: none;
}

.widget-full-width::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1);
    transition: transform 0.3s ease; /* Adicione uma transição suave */
}

.widget-full-width-6-0::before{
    background-image: url("../../../images/services/microServices/insurance_vehicles.png");
}

.widget-full-width-6-1::before{
    background-image: url("../../../images/services/microServices/insurance_properties.png");
}

.widget-full-width-6-2::before{
    background-image: url("../../../images/services/microServices/insurance_life.png");
}

.widget-full-width-6-3::before{
    background-image: url("../../../images/services/microServices/insurance_travel.png");
}

.widget-full-width:hover::before {
    transform: scale(1.08); /* Aplicar um zoom na imagem de fundo em hover */
}


.widget-content {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    height: 30%;
}

.text-container p {
    margin: 0;
    text-align: center;
    transition: transform 0.5s ease;
    font-size: 1.2em;
}

.button-container {
    width: 100%;
    overflow: hidden;
    transition: transform 0.5s ease; /* Adicione a transição para o botão */
    /*transform: translateY(100%); /* Começa oculto no fundo */

    /*margin-top: -10px;*/
    padding-bottom: 20px;
}

.appear {
    transform: translateY(0);
    transition: transform 0.5s ease;
}

.disappear {
    transform: translateY(-100%);
    transition: transform 0.5s ease;
}

.appear-button {
    transform: translateY(0);
    transition: transform 0.5s ease;
}

.disappear-button {
    transform: translateY(100%);
    transition: transform 0.5s ease;
}

.hovered .button-container,
.hovered .text-container {
    height: auto;
    transform: translateY(0);
}

.button-container > *:first-child{
    background-color: transparent;
    padding: 5px 25px;
    color: rgb(185, 0, 0);
    font-size: 1.2em;
    font-weight: bold;
    border-bottom: 2px solid rgb(185, 0, 0);
    border-radius: 2px;

    position: relative;
}

.button-container > *:first-child:hover{
    border: none;
    cursor: pointer;
}

.button-container > *:first-child:hover::before{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(185, 0, 0);
    transform-origin: center;
    transform: scaleX(0);
    animation: border-animation 1.5s ease-in forwards;

}
  
@keyframes border-animation{
    to{
        transform: scaleX(1);
    }
}

@media screen and (max-width: 1560px) {
    .text-container p {
        margin: 0;
        text-align: center;
        transition: transform 0.5s ease;
        font-size: 1em;
    }

    .button-container > *:first-child{
        background-color: transparent;
        padding: 5px 25px;
        color: rgb(185, 0, 0);
        font-size: 1em;
        font-weight: bold;
        border-bottom: 2px solid rgb(185, 0, 0);
        border-radius: 2px;
    
        position: relative;
    }

}

@media screen and (max-width: 768px) {
    .widget {
        width: 100%; /* Largura total para dispositivos móveis */
    }

    .widget-content {
        padding: 10px; /* Reduza o espaço interno para dispositivos móveis */
    }

    .footer {
        height: auto; /* Altura automática para se adaptar ao conteúdo */
    }

    .text-container p {
        font-size: 1em; /* Tamanho de fonte menor para dispositivos móveis */
    }

    .button-container > *:first-child {
        font-size: 1em; /* Tamanho de fonte menor para dispositivos móveis */
    }
}

@media screen and (max-width: 1500px){
    .button-container{
        margin-top: -7px;
    }
}

@media screen and (max-width: 1500px) and (min-height: 800px){
    .text-container{
        margin-bottom: 10px;
    }
}

