.tour-banner {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 15px;

    height: 31.25em;
    width: 18.75em;

    border-radius: .5rem;

    overflow: hidden;

    transition: transform 0.5s ease;
}

.tour-banner__title {
    font-size: 2rem;
    font-weight: bold;
    color: white;

    z-index: 1;
}

.tour-banner__info {
    font-size: 1.2rem;
    color: white;

    z-index: 1;
}

.tour-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url("../../images/tours/portugal/bikes/ilha_de_man_edited.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    z-index: 0;
    
    transition: transform 0.5s ease;
}

.tour-banner-id1::before {
    background-image: url("../../images/tours/portugal/bikes/ilha_de_man_edited.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.tour-banner-id2::before {
    background-image: url("../../images/tours/portugal/bikes/circuito_serra_mar_edited.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.tour-banner-id3::before {
    background-image: url("../../images/tours/portugal/bikes/circuito_serra_mar_edited.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.tour-banner:hover::before {
    transform: scale(1.2);
}

.tour-banner__icon {
    position: absolute;
    top: 15px;
    right: 15px;

    height: 25px;
    width: 25px;
    z-index: 2;

    display: block;
}

.brasil-icon {
    background-image: url("../../images/brasil_icon.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.portugal-icon {
    background-image: url("../../images/portugal_icon.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.tour-banner__btn {
    position: absolute;
    bottom: 15px;
    left: 15px;

    color: rgb(255, 3, 3);

    border: none;
    border-radius: 0.15rem;
    border-bottom: 2px solid rgb(255, 3, 3);

    z-index: 2;
    font-weight: bold;
}

.tour-banner__btn:active {
    border: none;
}