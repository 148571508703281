.about-container{
    height: 55vh;
    width: 100%;

    margin-top: clamp(25px, 5vw, 50px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    background-color: white;
}

.text-div-about{
    margin-left: 2em;
    padding-left: 1em;
    padding-right: 1em;

    height: 100%;
    width: 55%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
}

#title-about {
    display: flex;
    align-items: center;
    font-size: 4em;
    position: relative; /* Adicione position relative para posicionar o ::before */

    overflow: hidden; /* Para ocultar o texto que ainda não foi animado */
    white-space: nowrap;

    animation: typing-title 5s linear forwards;
}

#title-about span {
    display: inline-block;
    position: relative;
    animation: typing-title 5s linear forwards;
}

#title-about span::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 65%;
    height: 3px;
    background-color: rgb(185, 0, 0);
    transform-origin: center;
    transform: scaleX(0);
    animation: border-animation 2.5s linear forwards;
}

/*#title-about::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    border-bottom: 3px solid rgb(185, 0, 0); 
    border-radius: 3px;
}*/

.image-div{
    margin-right: 2em;
    margin-left: 2em;

    height: 100%;
    width: 45%;

    background-image: url("../../images/about_motorfest2.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    filter: blur(1px);
    border: none;

}

@keyframes typing-title {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes border-animation {
    to {
        transform: scaleX(1);
    }
}

@media screen and (max-width: 768px) {
    .about-container{
        height: 55vh;
        flex-direction: column;
    }

    .text-div-about{
        width: 80%;
    }
    
    #title-about{
        font-size: 2.5rem;
    }

    #title-about span::before{
        height: 2px;
    }

    .image-div{
        height: 55%;
        width: 80%;
    }

    .text-paragraph{
        font-size: 1.5rem;
    }
    
}

@media screen and (max-width: 900px) {
    .about-container{
        margin-bottom: 50px;
    }
}
