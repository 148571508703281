.story {
    height: 350px;
    width: 750px;
    border-radius: .5em;
    overflow: hidden; 

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;

    position: relative;
    top: -10em;
    left: 25em;
}

.carousel-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
    border-radius: inherit !important;
}

/* --------------------------------- */

.storyV2 {
    min-height: 37.5rem;
    max-height: 45rem;
    width: 100%;
    border-radius: .5em;
    overflow: hidden; 

    /*display: flex;
    justify-content: center;
    align-items: center;*/

    user-select: none;
}

.storyV2 .slide img {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    object-position: center;
    border-radius: inherit;
}