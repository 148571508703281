.services-container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 768px){

    .services-container{
        margin-bottom: 30px;
    }
}