@font-face{
  font-family: Noto Sans Sogdian; 
  src: url("fonts/Noto_Sans_Sogdian/NotoSansSogdian-Regular.ttf")
}

html {
  font-size: 16px; /* ou outro tamanho desejado */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: 'Noto Sans Sogdian', sans-serif;
}

@media screen and (max-width: 768px) {
  html {
      font-size: 10px;
  }
}
